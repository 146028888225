import {AuthenticationProvider} from "@/modules/shared/bridges/AuthenticationProvider";
import axios from "axios";
import {PosManagerRepository} from "@/modules/posManager/adapter/PosManagerRepository";
import moment from "moment-mini";

export class PosManagerRestRepository implements PosManagerRepository {
    private readonly auth: AuthenticationProvider;

    constructor(auth: AuthenticationProvider) {
        this.auth = auth;
    }

    private salesList = [ {
            "LABEL" : "Auswertungen",
            "ICON" : "posmanager-presentation-business",
            "GRIDNAME" : "",
            "FORMNAME" : "",
            "DISABLED" : false
          },
            {
                "LABEL" : "Bons",
                "ICON" : "posmanager-bill-credit-card",
                "GRIDNAME" : "",
                "FORMNAME" : "",
                "DISABLED" : false
            },
            {
                "LABEL" : "Kassenberichte",
                "ICON" : "posmanager-cash-register",
                "GRIDNAME" : "",
                "FORMNAME" : "",
                "DISABLED" : false
            }];

    public async receiveTaf(): Promise<boolean> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        if (sid) {
            const data = {
                SID: sid,
                Filiale: branchId
            }
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/receive_TAF`, data);

            if (response.data.receive_TAF_response?.ServiceErrors) {
                throw new Error(response.data.receive_TAF_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }

            return response.data.receive_TAF_response === null;
        } else {
            return Promise.reject();
        }
    }
    public async receivePersonalzeiten(): Promise<boolean> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        if (sid) {
            const data = {
                SID: sid,
                Filiale: branchId
            }
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/receive_Personalzeiten`, data);

            if (response.data.receive_Personalzeiten_response?.ServiceErrors) {
                throw new Error(response.data.receive_Personalzeiten_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }

            return response.data.receive_Personalzeiten_response === null;
        } else {
            return Promise.reject();
        }
    }
    public async sendMCustomers(mode: number): Promise<boolean> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        if (sid) {
            const data = {
                SID: sid,
                Mode: mode,
                Filiale: branchId
            }
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/send_Kunden`, data);

            if (response.data.send_Kunden_response?.ServiceErrors) {
                throw new Error(response.data.send_Kunden_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }

            return response.data.send_Kunden_response === null;
        } else {
            return Promise.reject();
        }
    }

    public async sendScaleData(mode: number): Promise<boolean> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        if (sid) {
            const data = {
                SID: sid,
                Mode: mode,
                Filiale: branchId
            }
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/send_Kassendaten`, data);

            if (response.data.send_Kassendaten_response?.ServiceErrors) {
                throw new Error(response.data.send_Kassendaten_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }

            return response.data.send_Kassendaten_response === null;
        } else {
            return Promise.reject();
        }
    }

    public async sendMasterdata(mode: number): Promise<boolean> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        if (sid) {
            const data = {
                SID: sid,
                Mode: mode,
                Filiale: branchId
            }
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/send_Stammdaten`, data);

            if (response.data.send_Stammdaten_response?.ServiceErrors) {
                throw new Error(response.data.send_Stammdaten_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }

            return response.data.send_Stammdaten_response === null;
        } else {
            return Promise.reject();
        }
    }

    public async sendArticle(mode: number): Promise<boolean> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        if (sid) {
            const data = {
                SID: sid,
                Mode: mode,
                Filiale: branchId
            }
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/send_Artikel`, data);

            if (response.data.send_Artikel_response?.ServiceErrors) {
                throw new Error(response.data.send_Artikel_response.ServiceErrors[0].ERRORDESCRIPTION);
            }

            return response.data.send_Artikel_response === null;
        } else {
            return Promise.reject();
        }
    }
    public async sendArticleList(selected:{RecordId:number}[]): Promise<boolean> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        if (sid) {
            const data = {
                SID: sid,
                Selected: selected,
                Filiale: branchId
            }
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/send_ArtikelList`, data);

            if (response.data.send_ArtikelList_response?.ServiceErrors) {
                throw new Error(response.data.send_ArtikelList_response.ServiceErrors[0].ERRORDESCRIPTION);
            }

            return response.data.send_ArtikelList_response === null;
        } else {
            return Promise.reject();
        }
    }
    public async isTransmitting(): Promise<boolean> {
        return await this.getConnectionStatus() !== 1;
    }

    public async getConnectionStatus(): Promise<number> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        const userId = this.auth.getUserId();
        if (sid) {
            const data = {
                SID: sid,
                UserID: userId,
                Filiale: branchId
            }
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/get_connection_status`, data);

            if (response.data.get_connection_status_response?.ServiceErrors) {
                throw new Error(response.data.get_connection_status_response.ServiceErrors[0].ERRORDESCRIPTION);
            }
            // nicht verbunden=0,
            // inaktiv=1,
            // aktiv=2,
            // Connect-Dienst nicht bereit=3

            return response.data.get_connection_status_response?.status;
        } else {
            return Promise.reject();
        }
    }

    public async getReports(): Promise<any[]> {
        //https://portal.aprocon.de:44380/POSManager?get_POSManagerReports
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        const userId = this.auth.getUserId();
        if (sid) {
            const data = {
                SID: sid,
                UserID: userId,
                Filiale: branchId
            }
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/get_POSManagerReports`, data);

            if (response.data.get_POSManagerReports_response?.ServiceErrors) {
                throw new Error(response.data.get_POSManagerReports_response.ServiceErrors[0].ERRORDESCRIPTION);
            }
            // nicht verbunden=0,
            // inaktiv=1,
            // aktiv=2,
            // Connect-Dienst nicht bereit=3

            return response.data.get_POSManagerReports_response?.POSManReports;
        } else {
            return Promise.reject();
        }
    }

    public async getReport(args: { reportNr: number, start: Date|undefined, end: Date|undefined , choice:number|undefined}): Promise<string> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        if (sid) {
            const data = {
                SID: sid,
                Filiale: branchId,
                ReportNr: args.reportNr,
                DatumVon: args.start ?moment(args.start).format("YYYY/MM/DD"):undefined,
                DatumBis: args.end ? moment(args.end).format("YYYY/MM/DD"):undefined,
                Auswahl: args.choice
            };
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/get_URL_POSManagerReport`, data,{ timeout:120000, timeoutErrorMessage : 'Es ist leider zu einer Zeitüberschreitung gekommen.\nGrund dafür kann eine zu große Datenmenge sein.'});
            if (response.data.get_URL_POSManagerReport_response?.ServiceErrors) {
                throw new Error(response.data.get_URL_POSManagerReport_response.ServiceErrors[0].ERRORDESCRIPTION);
            }
            return response.data.get_URL_POSManagerReport_response.ImageURL;
        } else {
            return Promise.reject();
        }
    }
    public getSalesGridList(): any[] {
        const sid = this.auth.getAuthToken();
        const user = this.auth.getUserId();

        if (sid) {
            const tabs = [];
            const apiTabs = this.salesList;
            for (let i = 0; i < apiTabs.length; i++) {
                // @ts-ignore
                tabs.push({
                    id: i,
                    route: `/umsaetze/${i}`,
                    name: apiTabs[i].LABEL,
                    icon: apiTabs[i].ICON,
                    gridName: apiTabs[i].GRIDNAME,
                    formName: apiTabs[i].FORMNAME,
                })
            }

            return tabs;
            // const response = await axios.post(`${this.auth.getServiceUrl()}/Stammdaten/Stammdaten_GetGridList`, {
            //     SID: sid,
            //     UserId: user
            // });
            // if (response.data.Stammdaten_GetGridList_response?.ServiceErrors) {
            //     throw new Error(response.data.Stammdaten_GetGridList_response.ServiceErrors[0].ERRORRECOMMENDATION);
            // } else {
            //     const tabs = new Array();
            //     const apiTabs = response.data.Stammdaten_GetGridList_response;
            //     for (let i = 0; i < apiTabs.length; i++) {
            //         tabs.push({
            //             id: i,
            //             route: `/stammdaten/${i}`,
            //             name: apiTabs[i].LABEL,
            //             icon: apiTabs[i].ICON,
            //             gridName: apiTabs[i].GRIDNAME,
            //             formName: apiTabs[i].FORMNAME,
            //         })
            //     }
            //
            //     return tabs;
        }
        return [];
    }

    public async getCashReport(date:string, id:number=-1): Promise<string> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        const userId = this.auth.getUserId();
        if (sid) {

            const data = {
                SID: sid,
                Filiale: branchId,
                Datum: date,
                KassenberichtRecordID:id,
                UserId: userId
            };
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/get_URL_POSManagerKassenbericht`, data,{ timeoutErrorMessage : 'Es ist leider zu einer Zeitüberschreitung gekommen.\nGrund dafür kann eine zu große Datenmenge sein.'});
            if (response.data.get_URL_POSManagerKassenbericht_response?.ServiceErrors) {
                throw new Error(response.data.get_URL_POSManagerKassenbericht_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }
            return response.data.get_URL_POSManagerKassenbericht_response.ImageURL;
        } else {
            return Promise.reject();
        }
    }
    public async getBonReport(id:number=-1): Promise<string> {
        const sid = this.auth.getAuthToken();
        const userId = this.auth.getUserId();
        if (sid) {

            const data = {
                SID: sid,
                UserId: userId,
                BonID: id
            };
            const response = await axios.post(`${this.auth.getServiceUrl()}/POSManager/get_URL_POSManagerBon`, data,{ timeoutErrorMessage : 'Es ist leider zu einer Zeitüberschreitung gekommen.\nGrund dafür kann eine zu große Datenmenge sein.'});
            if (response.data.get_URL_POSManagerBon_response?.ServiceErrors) {
                throw new Error(response.data.get_URL_POSManagerBon_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }
            return response.data.get_URL_POSManagerBon_response.ImageURL;
        } else {
            return Promise.reject();
        }
    }
}
